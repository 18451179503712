<template>
  <h3>{{ main.name }}</h3>
  <div class="card" ref="lblTop">
        <div class="card-header border-0 btn-toolbar justify-content-between" role="toolbar">
        <router-link class="btn light btn-warning btn-xs" :to="{name: 'eventos'}">
            <i class="fa-solid fa-trophy fa-lg"></i>
        </router-link >
        <div class="btn-group">
        <ul class="nav nav-pills dzm-tabs" id="myTab5" role="tablist">
          <router-link
            class="btn light btn-primary btn-xs nav-link"
            :class="{active: ($route.name == 'home-pruebas')}"
            :to="{name: 'home-pruebas', params: {id: meet_id}}"
          >
            <i class="fa-solid fa-flag fa-lg"></i>
        </router-link>
          <router-link
            class="btn light btn-primary btn-xs nav-link"
            :class="{active: ($route.name == 'home-clubes')}"
            :to="{name: 'home-clubes', params: {id: meet_id}}"
          >
            <i class="fa-solid fa-shield fa-lg"></i>
          </router-link>
          <router-link
            class="btn light btn-primary btn-xs nav-link"
            :class="{active: ($route.name == 'home-athletes')}"
            :to="{name: 'home-athletes', params: {id: meet_id}}"
          >
            <i class="fa-solid fa-person-swimming fa-lg"></i>
          </router-link>
          <router-link
            class="btn light btn-primary btn-xs nav-link"
            :class="{active: ($route.name == 'home-points')}"
            :to="{name: 'home-points', params: {id: meet_id}}"
          >
            <i class="fa-solid fa-medal fa-lg"></i>
          </router-link>
          
        </ul>
      </div>
        <div class="dropdown custom-dropdown mb-0">
		<div class="btn light btn-primary btn-xs" data-bs-toggle="dropdown">
            <i class="fa-solid fa-bars fa-lg"></i>
        </div>
		<div class="dropdown-menu dropdown-menu-end">
			<router-link class="dropdown-item" :to="{name: 'clubes'}">Acceso Clubes</router-link>
			<router-link class="dropdown-item" :to="{name: 'secretaria-home'}">Acceso Árbitros</router-link>
		</div>
	</div>
        </div>
  </div>
  
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" :key="route.name"> </component>
    </keep-alive>
  </router-view>


  
  <div class="fab-wrapper">
    <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
    <label class="fab" for="fabCheckbox">
      <span class="fab-dots fab-dots-1"></span>&nbsp;
      <span class="fab-dots fab-dots-2"></span>
      <span class="fab-dots fab-dots-3"></span>
    </label>
    <div class="fab-wheel">
      <a class="fab-action fab-action-1" @click="lblTop.scrollIntoView()">
        <i class="fa-solid fa-arrow-turn-up"></i>
      </a>
      <a class="fab-action fab-action-2" @click="recargaDatos">
        <i class="fa-solid fa-rotate"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { onActivated, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import useMeet from "../composables/useMeet";

export default {
  props: ["id"],
  setup() {
    const route = useRoute();
    const {
      loadMain,
      loadAgeGroups,
      loadEvents,
      loadSessions,
      loadPoints,
      reloadEntriesHeatsResults,
      main
    } = useMeet();


    const meet_id = ref();
    const lblTop = ref();

    const cargaDatosInicio = async (meet_id_load) => {
      meet_id.value = meet_id_load;
      await loadAgeGroups(meet_id_load);
      await loadAgeGroups(meet_id_load);
      await loadEvents(meet_id_load);
      await loadSessions(meet_id_load);
      await reloadEntriesHeatsResults(meet_id_load);
      await loadMain(meet_id_load);
      await loadPoints(meet_id_load);
    }

    onMounted(async () => {
      //console.log('montado');
      meet_id.value = route.params.id;
      cargaDatosInicio(meet_id.value)
      //await loadMain(meet_id.value);
      //await loadAgeGroups(meet_id.value);
    });

    onActivated(async () => {
      //console.log('activado');
    })

    const recargaDatos = async () => {
      await loadMain(meet_id.value);
      await loadAgeGroups(meet_id.value);
      await loadEvents(meet_id.value);
      await loadSessions(meet_id.value);
      await reloadEntriesHeatsResults(meet_id.value);
    };

    watch(route, (to, from) => {
      // console.log(to.params.id, meet_id.value);
      if(to.params.id != meet_id.value) {
        if(to.params.id) {
          cargaDatosInicio(to.params.id)
        }
      }
    })

    return {
      main,
      meet_id,
      lblTop,
      recargaDatos
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.fab-wrapper {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}
.fab-checkbox {
  display: none;
}
.fab {
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  width: 3.5rem;
  height: 3.5rem;
  background: #0d99ff;
  border-radius: 50%;
  background: #0d99ff;
  box-shadow: 0px 5px 20px #81a4f1;
  transition: all 0.3s ease;
  z-index: 1;
  border-bottom-right-radius: 6px;
  border: 1px solid #0d99ff;
}

.fab:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}
.fab-checkbox:checked ~ .fab:before {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.2);
}
.fab:hover {
  background: #0d99ff;
  box-shadow: 0px 2px 20px 2px #81a4f1;
}

.fab-dots {
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(0%) translateY(-50%) rotate(0deg);
  opacity: 1;
  transition: all 0.3s ease;
}

.fab-dots-1 {
  left: 15px;
}
.fab-dots-2 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.fab-dots-3 {
  right: 15px;
}

.fab-checkbox:checked ~ .fab .fab-dots {
  height: 6px;
}

.fab .fab-dots-2 {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked ~ .fab .fab-dots-1 {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.fab-checkbox:checked ~ .fab .fab-dots-3 {
  width: 32px;
  border-radius: 10px;
  right: 50%;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.fab-checkbox:checked ~ .fab .fab-dots {
  animation: none;
}

.fab-wheel {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid transparent;
  width: 10rem;
  height: 8rem;
  transition: all 0.3s ease;
  transform-origin: bottom right;
  transform: scale(0);
}

.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
}
.fab-action {
  position: absolute;
  background: #0f1941;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  box-shadow: 0 0.1rem 1rem rgba(24, 66, 154, 0.82);
  transition: all 1s ease;

  opacity: 0;
}

.fab-checkbox:checked ~ .fab-wheel .fab-action {
  opacity: 1;
}

.fab-action:hover {
  background-color: #f16100;
}

.fab-wheel .fab-action-1 {
  right: -1rem;
  top: 0;
}

.fab-wheel .fab-action-2 {
  right: 3.4rem;
  top: 0.5rem;
}
.fab-wheel .fab-action-3 {
  left: 0.5rem;
  bottom: 3.4rem;
}
.fab-wheel .fab-action-4 {
  left: 0;
  bottom: -1rem;
}
</style>